/* Global Styles */
:root {
    --primary-color: #1a9cd4;
    --secondary-color: #f2f2f2;
    --border-color: #333;
    --border-radius: 10px;
    --input-height: 2em;
    --input-padding: 6px;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: scroll;
}

html {
    height: 100%;
}

/* Container */
.container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}

/* Header Wrapper Styles */
.header-wrapper {
    position: sticky;
    top: 20px;
    z-index: 1000;
    margin-bottom: 20px;
    background-color: #fff;
    padding-bottom: 10px;
}

/* Header Styles */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    background-color: #1a9cd4;
    color: white;
    border: 1px solid #333;
    border-radius: 10px;
}

.date-change-icon {
    font-size: 1.5em;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease, font-size 0.3s ease;
}

.date-change-icon:hover {
    color: #c5c5c5;
}

.date-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-display {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

/* Section Styles */
section {
    margin-top: 20px;
    padding: 10px 20px 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    border: 1px solid #333;
}

section h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

/* Form Styles */
.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.form-group label {
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
    flex-basis: auto;
    white-space: nowrap;
    padding-bottom: .25em;
}

.form-group label::after {
    content: ":";
}

input[type="date"],
input[type="time"],
input[type="number"],
select,
textarea {
    padding: 6px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 14px;
    height: 2em;
}

input[type="date"] {
    height: 2em;
}

input[type="time"],
input[type="number"] {
    width: 120px;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
}

select {
    height: 2em;
    margin-right: 1em;
    width: 100%;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    transform: scale(1.5);
    margin-left: 10px;
    margin-bottom: 1em;
    margin-top: .75em;
}

textarea {
    flex-grow: 1;
    margin-bottom: 10px;
}

/* Button Styles */
.form-group button {
    margin: .25em;
    height: 2.25em;
    width: 2.5em;
    font-size: .85em;
    font-weight: 700;
    background-color: #1a9cd4;
    border-style: none;
    color: white;
    border-radius: 8px;
    margin-right: .5em;
    display: inline-block;
}

.form-group button+button {
    margin-left: 10px;
}

/* Footer Styles */
footer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* Changed from flex-end to center */
    align-items: center;
    gap: 8px;
    /* Reduced from 10px */
    width: 100%;
    padding: 0 10px;
    /* Added padding for small screens */
}

footer button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #1a9cd4;
    border: 1px solid #333;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

/* Quill Editor Styles */
.quill {
    min-height: 150px;
    width: 100%;
    margin-top: 1em;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 8px;
    padding-bottom: 0px;
}

.ql-container {
    min-height: 150px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 10px;
}

.ql-editor {
    min-height: 100px;
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: black;
    background-color: white;
}

.ql-editor ul {
    padding-left: 20px;
    margin-left: 0;
    list-style-position: inside;
}

.ql-editor p,
.ql-editor {
    text-align: initial;
}

.ql-editor ul,
.ql-editor ol {
    padding-left: 0;
}

.ql-editor li {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: .5em;
}

.ql-editor li::before {
    margin-left: 0;
    margin-right: 0.5em;
}

/* Weather Section Styles */
.weather-section {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.weather-section h2 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.weather-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.weather-row {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.weather-item {
    display: flex;
    align-items: center;
}

.weather-label {
    font-weight: bold;
    margin-right: 5px;
    white-space: nowrap;
}

.weather-value {
    font-size: 1.1em;
}

.pollen-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.weather-value {
    display: flex;
    align-items: center;
}

/* Sleep Section Styles */
.sleep-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 15px;
}

.sleep-row .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
}

.sleep-row .form-group:last-child {
    margin-right: 0;
}

.sleep-row label {
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;
}

.sleep-row input {
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    height: 2em;
}

.sleep-row input[type="time"] {
    width: 85px;
}

.sleep-row input[type="number"] {
    width: 60px;
}

/* Beverages Section Styles */
.beverages-container {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-right: 0;
}

.beverage-column {
    flex: 1;
    width: calc(50% - 10px);
}

.beverage-column h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.beverage-row {
    display: flex;
    align-items: center;
    margin-top: 1.5em;
    height: 2.5em;
    width: 100%;
}

.beverage-row .form-group {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Space between label and input */
}

.beverage-row label {
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    /* Remove margin to tighten spacing */
}

.beverage-row input[type="time"] {
    width: 110px;
    height: 2em;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.beverage-buttons {
    display: flex;
    gap: 10px;
    margin-left: 40px;
    margin-bottom: 15px;
    /* Reduced from auto to bring buttons closer */
}

.beverage-buttons button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #1a9cd4;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.beverage-buttons button:hover {
    background-color: #158ec3;
}

.beverage-buttons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .beverages-container {
        flex-direction: column;
        gap: 15px;
    }

    .beverage-column {
        width: 100%;
    }

    .beverage-row input[type="time"] {
        width: 100px;
    }
}

/* Food Section Styles */
.food-quill {
    min-height: 150px;
    border-radius: 5px;
    width: 100%;
    margin-top: 1em;
}

.food-quill .ql-container {
    min-height: 150px;
    border-radius: 5px;
    width: 100%;
}

.food-quill .ql-editor {
    min-height: 100px;
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: black;
    background-color: white;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.ql-toolbar .ql-formats .ql-insertTime::after {
    content: 'Time';
    margin-left: 4px;
    font-size: 14px;
}

.ql-toolbar .ql-formats .ql-insertTime {
    margin-left: auto;
    background-color: #1a9cd4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
}

.ql-toolbar .ql-formats .ql-insertTime:hover {
    background-color: #1a9cd4;
}

.food-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cheat-day-checkbox {
    display: flex;
    align-items: center;
}

.cheat-day-checkbox label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.cheat-day-checkbox input[type="checkbox"] {
    margin-right: 5px;
    transform: scale(1.2);
}

.cheat-day-checkbox label::after {
    content: none;
}

/* Mood Section Styles */

.mood-entry {
    margin-bottom: 2em;
}

.mood-row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.mood-row .form-group,
.mood-row .form-group-flag,
.mood-row .form-group-red-zone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.mood-row .form-group {
    width: 140px; 
    margin-right: 20px;
}

.mood-row .form-group-flag,
.mood-row .form-group-red-zone {
    width: 80px;
    margin-bottom: 10px;
}

.mood-row label {
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
}

.mood-row input {
    padding: 6px;
    box-sizing: border-box;
    height: 2.75em;
}

.mood-row input[type="number"] {
    width: 60px;
}

.mood-row input[type="time"] {
    width: 85px;
}

.mood-row input[type="checkbox"] {
    margin-top: 0.75em;
    transform: scale(1.5);
    height: 1.5em;
    margin-left: 0px;
}

.mood-buttons {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    margin-bottom: 15px;
}

.mood-buttons button {
    margin: 0 5px;
    height: 30px;
    width: 30px;
    font-size: 18px;
    font-weight: 700;
    background-color: #1a9cd4;
    border: none;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* New styles for desktop screens */
@media (min-width: 1024px) {
    .mood-row {
        justify-content: space-between;
    }

    .mood-buttons {
        margin-left: auto;
    }

    .beverage-row .form-group input[type="time"] {
        height: 1.5em;
        margin-bottom: 1.5em;
        margin-top: 1.25em;
    }
}
/* Exercise Section Styles */
.exercise-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 15px;
}

.exercise-row .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
}

.exercise-row .form-group:last-child {
    margin-right: 0;
}

.exercise-row label {
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;
}

.exercise-row input[type="number"] {
    width: 60px;
    padding: 6px;
    box-sizing: border-box;
    height: 2em;
}

.exercise-row input[type="checkbox"] {
    margin-top: 5px;
    transform: scale(1.5);
}

/* Medication Section Styles */
.medication-row {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin-bottom: 15px;
    padding: 15px;
}

.medication-row.alternate-row {
    background-color: #f9f9f9;
}

.medication-row .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
}

/* Medication Section Styles (continued) */
.medication-row .form-group:last-child {
    margin-right: 0;
}

.medication-row label {
    display: block;
    margin-bottom: 5px;
    white-space: nowrap;
}

.medication-row .medication-select {
    width: 40%;
}

.medication-row .medication-select select {
    width: 100%;
    height: 2em;
    margin-bottom: .25em;
}

.medication-row input[type="time"],
.medication-row input[type="number"] {
    width: 85px;
    height: 2em;
    padding: 6px;
    box-sizing: border-box;
}

.medication-row .medication-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.medication-buttons {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 21px;
}

.medication-row .medication-buttons button {
    margin: 0 5px;
    height: 30px;
    width: 30px;
    font-size: 18px;
    font-weight: 700;
    background-color: #1a9cd4;
    border: none;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.medication-row input[type="number"] {
    -moz-appearance: auto;
}

.medication-row input[type="number"]::-webkit-outer-spin-button,
.medication-row input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: auto;
    margin: 0;
}

.beverage-buttons button:disabled,
.mood-buttons button:disabled,
.medication-buttons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Print View Styles */
.print-view {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.day-data {
    margin-bottom: 40px;
    page-break-after: always;
}

.day-data h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.day-data h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.day-data p {
    margin: 5px 0;
}

/* Modal Dialog Styles */
.ant-modal-header {
    background-color: #1a9cd4;
    color: white;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #1a9cd4;
    border-color: #1a9cd4;
}

.ant-modal-footer .ant-btn-primary:hover {
    background-color: #0a74a3;
    border-color: #0a74a3;
}

.ant-modal-footer .ant-btn-default {
    background-color: #ffffff;
}

.ant-modal-content {
    border-radius: 10px;
}

/* Icon Wrapper Styles */
.data-icon-wrapper,
.chart-icon-wrapper,
.printer-icon-wrapper {
    border: 2px solid #bdbaba;
    border-radius: 10%;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 40px;
}

.data-icon-wrapper:hover,
.chart-icon-wrapper:hover,
.printer-icon-wrapper:hover {
    border-color: #1a9cd4;
}

/* Print Date Range Styles */
.print-date-range {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 2em;
}

.print-date-range label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.print-date-range input {
    margin-top: 5px;
    padding: 5px;
}

/* =================
   Modal Base Styles 
   ================= */
/* Basic styling for all notes-related modals */
.general-notes-content .quill {
    background-color: white;
    padding-bottom: 0px;
}

.general-notes-content .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #ccc;
}

.general-notes-content .ql-container {
    min-height: 200px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-top: 0;
}

.general-notes-content .ql-editor {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    background-color: white;
}

.general-notes-content .ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: none;
}

/* =================
   Footer Button Styles 
   ================= */
/* Styles for the notes buttons in the main page footer */
.notes-icon-wrapper {
    border: 2px solid #bdbaba;
    border-radius: 10%;
    padding: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 70px;
}

.notes-icon-wrapper:hover {
    border-color: #1a9cd4;
}

/* Container for the icon and label within each footer button */
.icon-label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.icon-label {
    font-size: 11px;
    color: #1a9cd4;
    white-space: nowrap;
}

/* Container for all footer buttons */
.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 10px;
}

/* Size setting for icons in the footer */
.notes-icon-wrapper .anticon {
    font-size: 20px;
}

/* =================
   View Notes Content Styles 
   ================= */
/* Styles for the notes list view */
.view-notes-content {
    font-family: Arial, sans-serif;
}

/* Individual note container */
.note-entry {
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e8e8e8;
}

.note-entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

/* Header area of each note containing date and action buttons */
.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* Container for edit/delete/save/cancel buttons */
.note-actions {
    display: flex;
    gap: 12px;
    align-items: center;
}

/* Date display in each note */
.note-date {
    font-weight: bold;
    color: #1a9cd4;
    font-size: 16px;
}

/* Note content display */
.note-text {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
}

/* Message shown when no notes exist */
.no-notes-message {
    text-align: center;
    color: #999;
    font-style: italic;
    padding: 20px;
}

/* =================
   Action Icons Styling 
   ================= */
/* Base styles for all action icons */
.delete-note-icon,
.edit-note-icon,
.save-note-icon,
.cancel-edit-icon {
    font-size: 16px;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease;
}

/* Individual icon colors and hover states */
.edit-note-icon {
    color: #1a9cd4;
}

.edit-note-icon:hover {
    color: #40a9ff;
    transform: scale(1.1);
}

.delete-note-icon {
    color: #ff4d4f;
}

.delete-note-icon:hover {
    color: #ff7875;
    transform: scale(1.1);
}

.save-note-icon {
    color: #52c41a;
}

.save-note-icon:hover {
    color: #73d13d;
    transform: scale(1.1);
}

.cancel-edit-icon {
    color: #faad14;
}

.cancel-edit-icon:hover {
    color: #ffc53d;
    transform: scale(1.1);
}

/* Disabled state for icons */
.delete-note-icon[disabled],
.save-note-icon[disabled] {
    color: #d9d9d9;
    cursor: not-allowed;
    transform: none;
}

/* =================
   Note Editor Styles 
   ================= */
/* Styles for the edit mode of notes */
.note-editor {
    margin-top: 15px;
    margin-bottom: 20px;
}

.note-editor .quill {
    background-color: white;
    border-radius: 4px;
}

.note-editor .ql-container {
    min-height: 150px;
    font-size: 14px;
}

.note-editor .ql-editor {
    min-height: 150px;
}

/* =================
   Rich Text Content Styles 
   ================= */
/* Styles for rendered rich text content */
.note-text p {
    margin-bottom: 10px;
}

.note-text ul,
.note-text ol {
    margin-left: 20px;
    margin-bottom: 10px;
}

.note-text li {
    margin-bottom: 5px;
}

/* =================
   Responsive Styles 
   ================= */
/* Styles for mobile devices */
@media (max-width: 600px) {

    /* Smaller footer buttons on mobile */
    .notes-icon-wrapper {
        width: 60px;
        padding: 6px;
    }

    .notes-icon-wrapper .anticon {
        font-size: 18px;
    }

    .icon-label {
        font-size: 10px;
    }

    .button-container {
        gap: 6px;
        padding: 0 5px;
    }

    /* Adjust note display for mobile */
    .note-date {
        font-size: 14px;
    }

    .note-text {
        font-size: 13px;
    }
}

/* Styles for very small screens */
@media (max-width: 360px) {
    .notes-icon-wrapper {
        width: 55px;
        padding: 4px;
    }

    .icon-label {
        font-size: 9px;
    }

    .button-container {
        gap: 4px;
    }
}

/* Responsive Styles */
/* For larger screens (desktops) */
@media (min-width: 1024px) {
    .container {
        width: 60%;
        max-width: 900px;
    }

    header {
        font-size: 28px;
        padding: 15px;
    }

    .date-display {
        font-size: 28px;
    }

    section h2 {
        font-size: 28px;
    }

    .form-group.label-group-mood {
        display: flex;
        gap: 15px;
        align-items: center;
        margin-right: 2em;
    }

    .form-group.input-row {
        display: flex;
        gap: 20px;
        justify-content: flex-start;
        align-items: center;
    }

    .form-group.input-row .form-group {
        flex-basis: auto;
        margin-bottom: 0;
        width: 100%;
    }

    .form-group input[type="time"],
    .form-group input[type="number"] {
        width: 128px;
        font-size: 16px;
        height: 2.35em;
    }

    .form-group select {
        width: 70%;
        font-size: 16px;
        height: 2em;
    }

    .form-group button {
        width: 2.5em;
        margin: 0;
        border-radius: 4px;
        margin-left: 2em;
        margin-bottom: .75em;
    }

    footer button {
        width: auto;
        font-size: 18px;
    }

    .button-container {
        flex-direction: row;
        gap: 20px;
        height: 3em;
        margin: 1em;
    }
}

/* For mobile devices */
@media (max-width: 600px) {
    .container {
            padding: 10px;
            border-width: 1px;
    }

    /* Add these new styles for the header */
    .header-wrapper {
        top: 10px;
        padding-bottom: 5px;
    }

    header {
        font-size: 18px;
        padding: 8px;
    }

    .date-display {
        font-size: 16px;
    }

    section h2 {
        font-size: 20px;
    }

    .form-group label {
        font-size: 12px;
    }

    .form-group input[type="time"],
    .form-group input[type="number"],
    .form-group select {
        width: 100%;
        font-size: 14px;
    }

    .form-group button {
        width: 5%;
        margin: 0.5em 0;
    }

    footer {
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .button-container {
        justify-content: flex-end;
        width: 100%;
    }

    /* Icon wrapper styles for mobile */
    .data-icon-wrapper,
    .chart-icon-wrapper,
    .printer-icon-wrapper {
        width: 30px;
        height: 30px;
        padding: 5px;
    }

    /* Adjust icon size for mobile */
    .data-icon-wrapper .anticon,
    .chart-icon-wrapper .anticon,
    .printer-icon-wrapper .anticon {
        font-size: 20px;
    }

    /* Sleep section adjustments */
    .sleep-row {
        flex-wrap: wrap;
    }

    .sleep-row .form-group {
        width: 48%;
        margin-right: 2%;
        margin-bottom: 10px;
    }

    /* Exercise section adjustments */
    .exercise-row {
        flex-wrap: wrap;
    }

    .exercise-row .form-group {
        width: 48%;
        margin-right: 2%;
        margin-bottom: 10px;
    }

    /* Mood section adjustments */

        .mood-row {
            flex-wrap: wrap;
        }
    
        .mood-row .form-group,
        .mood-row .form-group-flag,
        .mood-row .form-group-red-zone {
            width: 22%;
            margin-right: 2%;
            margin-bottom: 10px;
        }
    
        .mood-row .form-group:first-child {
            width: 18%;
        }
    
        .mood-buttons {
            width: 30%;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }

    /* Medication section adjustments for mobile */
    .medication-row {
        flex-wrap: wrap;
        padding: 10px 0;
    }

    .medication-row .form-group {
        margin-right: 20px;
        margin-bottom: 13px;
    }

    .medication-row .medication-select {
        width: 40%;
        margin-bottom: 10px;
    }

    .medication-row .medication-fields-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .medication-row .form-group input[type="number"],
    .medication-row .form-group input[type="time"] {
        width: 120px;
        font-size: 14px;
    }

    /* Add space between Qty and Time fields */
    .medication-row .form-group:nth-child(2) {
        margin-right: 20px;
    }

    .medication-buttons {
        display: flex;
        margin-bottom: 15px;
        margin-left: 10px;
    }

    .medication-buttons button {
        width: 30px;
        height: 30px;
        font-size: 18px;
        padding: 0;
        margin: 0 2px;
    }

    /* Ensure the footer doesn't overflow on small screens */
    footer {
        margin-top: 20px;
        padding-bottom: 20px;
    }

/* Print-specific styles */
@media print {
    body {
        font-size: 12pt;
    }

    .print-view {
        max-width: 100%;
    }

    .day-data {
        page-break-after: always;
    }
}

/* Mobile-specific styles for Weather section */
@media (max-width: 600px) {
    .weather-info {
        flex-direction: column;
    }

    .weather-row {
        flex-wrap: wrap;
        gap: 10px;
    }

    .weather-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .weather-label {
        margin-bottom: 5px;
    }

    .weather-value {
        font-size: 1em;
        margin-left: 10px;
    }

    /* Specific adjustments for the first row */
    .weather-row:first-child {
        display: flex;
        flex-wrap: nowrap;
    }

    .weather-row:first-child .weather-item:first-child {
        width: auto;
        margin-right: 20px;
    }

    .weather-row:first-child .weather-item:last-child {
        flex-grow: 1;
    }

    /* Specific adjustments for the second row */
    .weather-row:nth-child(2) {
        justify-content: space-between;
    }

    .weather-row:nth-child(2) .weather-item {
        width: calc(33.33% - 7px);
    }

    /* Exercise section adjustments for mobile */
    .exercise-row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .exercise-row .form-group {
        margin-right: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .exercise-row label {
        font-size: 12px;
        white-space: nowrap;
        margin-bottom: 2px;
    }

    .exercise-row input[type="number"] {
        width: 70px;
        font-size: 14px;
    }

    /* Specific adjustments for Walking Miles and Ruck Weight */
    .exercise-row .form-group:nth-child(1),
    .exercise-row .form-group:nth-child(2) {
        margin-right: 20px;
    }

    .exercise-row .form-group:nth-child(1) input[type="number"],
    .exercise-row .form-group:nth-child(2) input[type="number"] {
        width: 90px;
    }

    /* Resistance checkbox adjustments */
    .exercise-row .form-group:last-child {
        flex-direction: column;
        align-items: flex-start;
    }

    .exercise-row .form-group:last-child label {
        margin-bottom: 2px;
        margin-top: 5px;
    }

    .exercise-row input[type="checkbox"] {
        margin-top: 0;
        transform: scale(1.2);
        margin-left: 0px;
        margin-bottom: 1px;
    }
}

/* Weekly Summary Modal Styles */
/* Add these styles to your styles.css file */

/* Weekly Summary Modal Styles */
.weekly-summary {
    font-family: Arial, sans-serif;
}

.summary-section {
    margin-bottom: 15px;
    /* Reduced from 20px to accommodate new content */
    padding-bottom: 10px;
}

.summary-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.summary-section h3 {
    font-size: 18px;
    margin-bottom: 8px;
    /* Reduced from 10px */
    color: #1a9cd4;
    padding-bottom: 5px;
    border-bottom: 1px solid #e8e8e8;
}

.summary-section p {
    margin: 8px 0;
    /* Reduced from 15px */
    line-height: 1.4;
    font-size: 14px;
}

.summary-section p strong {
    display: inline-block;
    min-width: 200px;
    color: #333;
}

/* Modal Button Styles */
.modal-button {
    background-color: #1a9cd4;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.modal-button:hover {
    background-color: #0a74a3;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {
    .summary-section p {
        font-size: 13px;
        margin: 6px 0;
    }

    .summary-section p strong {
        min-width: 160px;
    }

    .summary-section h3 {
        font-size: 16px;
    }
}

/* iPhone 14 specific styles */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

    .mood-row .form-group,
        .mood-row .form-group-flag,
        .mood-row .form-group-red-zone {
            width: 22%;
            margin-right: 2%;
        }
    
        .mood-row .form-group:first-child {
            width: 18%;
        }
}
